import {
  Address,
  Transport,
  createClient,
  createPublicClient,
  http,
  keccak256,
  zeroAddress
} from 'viem'
import * as chains from 'wagmi/chains'
import metadata from '@superfluid-finance/metadata'
import {
  TokenInfo,
  extendedSuperTokenList
} from '@superfluid-finance/tokenlist'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'

export const superfluidRpcUrls = metadata.networks.reduce((acc, network) => {
  acc[network.chainId as ChainId] = http(
    `https://rpc-endpoints.superfluid.dev/${network.name}`
  )
  return acc
}, {} as Record<ChainId, Transport>)

export const supportedChains = [
  // arbitrum: chains.arbitrum,
  // arbitrumGoerli: chains.arbitrumGoerli,
  // avalanche: chains.avalanche,
  // avalancheFuji: chains.avalancheFuji,
  chains.base,
  // baseGoerli: chains.baseGoerli,
  // bsc: chains.bsc,
  ...(process.env.NEXT_PUBLIC_ENV === 'dev' ? [chains.celo] : []),
  // gnosis: chains.gnosis,
  // goerli: chains.goerli,
  // mainnet: chains.mainnet,
  chains.optimism
  // optimismGoerli: chains.optimismGoerli,
  // polygon: chains.polygon,
  // polygonMumbai: chains.polygonMumbai
] as const

export const isTestnet = (chain: SupportedChain) => chain?.testnet

export type SupportedNetworkName = keyof typeof supportedChains

export const supportedChainsById = supportedChains.reduce(
  (acc, chain) => Object.assign(acc, { [chain.id]: chain }),
  {} as Record<ChainId, SupportedChain>
)

export const subgraphUrls = supportedChains.reduce((acc, chain) => {
  const canonicalName = metadata.networks.find(
    ({ chainId }) => chainId === chain.id
  )?.name

  return Object.assign(
    acc,
    canonicalName
      ? {
          [chain.id]: {
            url: `https://${canonicalName}.subgraph.x.superfluid.dev`,
            fallback: `https://https://api.thegraph.com/subgraphs/name/superfluid-finance/protocol-v1-${canonicalName}`
          }
        }
      : {}
  )
}, {} as Record<ChainId, { url: string; fallback: string }>)

export type SupportedChain = typeof supportedChains[number]
export type ChainId = SupportedChain['id']

export type NetworkAssets = Record<
  keyof typeof supportedChains,
  {
    logoURI: string
    color: `#${string}`
  }
>

export const networkAssets: NetworkAssets = Object.keys(supportedChains).reduce(
  (acc, name) =>
    Object.assign(acc, {
      [name]: {
        logoURI: `/network/${name}.svg`,
        color: `#${keccak256(`0x${name}`, 'hex').substring(2, 8)}`
      }
    }),
  {} as NetworkAssets
)

export const staticClients = Object.values(supportedChains).reduce(
  (acc, chain) => {
    const client = createClient({
      batch: {
        multicall: true
      },
      chain,
      transport: superfluidRpcUrls[chain.id]
    })

    return Object.assign(acc, { [chain.id]: client })
  },
  {} as Record<ChainId, ReturnType<typeof createPublicClient>>
)

export const createNativeAssetToken = (
  nativeAssetSuperToken: SuperTokenInfo,
  address: Address = zeroAddress
): TokenInfo => {
  const nativeCurrency =
    supportedChainsById[nativeAssetSuperToken.chainId as ChainId]
      ?.nativeCurrency

  return {
    address,
    chainId: nativeAssetSuperToken.chainId,
    logoURI: nativeAssetSuperToken.logoURI,
    ...nativeCurrency
  }
}

export const nativeAssetSupertokensByNetwork: Record<
  ChainId,
  {
    superToken: SuperTokenInfo
    underlyingToken: TokenInfo
    coingeckoId: string
  }
> = {
  [chains.celo.id]: {
    ...(() => {
      const superToken = extendedSuperTokenList.tokens.find(
        (token) =>
          token.address ===
          '0x671425ae1f272bc6f79bec3ed5c4b00e9c628240'.toLowerCase()
      ) as SuperTokenInfo

      return {
        superToken,
        underlyingToken: createNativeAssetToken(
          superToken,
          '0x471ece3750da237f93b8e339c536989b8978a438'
        )
      }
    })(),
    coingeckoId: 'celo'
  },
  [chains.base.id]: {
    ...(() => {
      const superToken = extendedSuperTokenList.tokens.find(
        (token) =>
          token.address ===
          '0x46fd5cfb4c12d87acd3a13e92baa53240c661d93'.toLowerCase()
      ) as SuperTokenInfo

      return {
        superToken,
        underlyingToken: createNativeAssetToken(superToken, zeroAddress)
      }
    })(),

    coingeckoId: 'ethereum'
  },
  [chains.optimism.id]: {
    ...(() => {
      const superToken = extendedSuperTokenList.tokens.find(
        (token) =>
          token.address ===
          '0x4ac8bd1bdae47beef2d1c6aa62229509b962aa0d'.toLowerCase()
      ) as SuperTokenInfo

      return {
        superToken,
        underlyingToken: createNativeAssetToken(superToken, zeroAddress)
      }
    })(),

    coingeckoId: 'ethereum'
  }
}
